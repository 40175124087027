import * as React from "react";
import { TravelAgentRewardsProps } from "./typings";
import { Rewards } from "@shared-ui/retail-travel-agent-rewards";

export const TravelAgentRewards = (props: TravelAgentRewardsProps) => {
  const { templateComponent } = props;
  const { id } = templateComponent.metadata;

  return (
    <div id={id} data-testid="travel-agent-rewards">
      <Rewards inputs={{}} />
    </div>
  );
};

export default TravelAgentRewards;
